.ag-header-row {
    background: #f8f8f8;
    
}

.ag-root {
    /* border-radius: 20px; */
    box-shadow: "0px 0px 8px rgba(0, 0, 0, 0.06)";
    /* border: 1px solid red; */
}

.tag {
  padding: 4px;
  padding-left: 4px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 13px;
  text-transform: capitalize;
}

.paidTag {
  border: 1.5px solid rgb(70, 227, 114, 0.2);
  color: rgb(62, 184, 97);
  padding-right: 8px;
}

.pendingTag {
  border: 1px solid #cccccc;
}

.tick {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
.ag-side-bar .ag-side-buttons {
  display: none;
}

.ag-pinned-right-cols-container{
  margin-right: -0px !important;
}

.ag-root-wrapper {
  overflow: visible;
  z-index: 0;
}