@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f9fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-screen {
    @apply max-w-screen-xl mx-auto px-4 md:px-8;
}

.custom-btn-bg {
    background: radial-gradient(67.5% 67.5% at 50% 50%, #4B5563 0%, rgba(75, 85, 99, 0) 100%)
}

.custom-btn-bg:hover {
    background: radial-gradient(118.75% 118.75% at 50% 50%, #4B5563 0%, rgba(75, 85, 99, 0) 100%);
}


.ag-row {
  border-bottom: none !important;
}
.ag-theme-material {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(249, 245, 227);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);
  --ag-alpine-accent-color: #e85936 !important;
  --ag-alpine-primary-color: #e85936 !important;
  --ag-checkbox-checked-color: #e85936 !important;
  --ag-row-hover-color: #e3e8fe94 !important;
  --ag-font-size: 17px;
  --ag-font-family: "Inter" !important;
  --ag-alpine-active-color: #e85936 !important;
  --ag-material-primary-color: #e85936 !important;
}
.ag-sort-indicator-icon.ag-sort-descending-icon,
.ag-sort-indicator-icon.ag-sort-ascending-icon {
  color: #e85936;
}
.ag-sort-indicator-icon.ag-sort-order {
  color: #1a0a06;
}
.ag-header-icon {
  cursor: pointer;
}
/* .ag-cell-wrapper {
  margin-top: 20px !important;
} */
.ag-selection-checkbox {
  margin-top: -25px !important;
}
.cell-wrap-text {
  white-space: normal !important;
}

.ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: #e85936 !important;
}
.ag-theme-material .ag-side-button-button:hover {
  color: #e85936 !important;
}
.ag-header-cell-label span.ag-icon.ag-icon-filter {
  color: #e85936;
}


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}